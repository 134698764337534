<template>
    <div>
        <multiselect
            v-model="inputVal.value"
            :options="Object.keys(getDocumentsList())"
            :multiple="false"
            :custom-label="opt => getDocumentsList()[opt]"
            :searchable="true"
        >
        </multiselect>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getDocuments']),
        getDocumentsList() {
            let result = {}
            result['kva'] = this.$t('invoices.document.kva')

            this.getDocuments().forEach(document => {
                result[document] = this.$t('invoices.document.' + document)
            })
            return result
        },
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>